<template>
  <div>
    <div class="header-container">
      <div>
        <v-breadcrumbs>
          <v-breadcrumbs-item class="breadcrumbs-hover-default">
            <i class="fal fa-clipboard-list-check" style="margin:0 10px 0 0;"></i>
            <span @click="main()">Activity Logs</span>
          </v-breadcrumbs-item>
        </v-breadcrumbs>
      </div>
    </div>
    <div class="page-content">
      <div class="content">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {}
  },

  methods: {
    main() {
      this.$router.push({
        name: "admin.log"
      }).catch(() => {

      })
    },
  }
}
</script>

<style scoped lang="scss">

</style>
